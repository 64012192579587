import { autoinject } from "aurelia-dependency-injection";
import { bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";
import { PatientItem } from "resources/classes/Patient/PatientItem";
import { PprInfo } from "./PprInfo";
import { ConfigService } from "resources/services/ConfigService";
const moment = require("moment");

@autoinject
export class PatientPpr {
    @bindable patient : PatientItem;
    info : PprInfo;
    message : string;
    messageColor: string;
    iconColor : string;

    constructor(protected router : Router, protected i18n : I18N) {
    }

    async patientChanged(p : PatientItem) {        
        this.info = p?.ppr || await PprInfo.Load(p, this.i18n);
        this.message = this.info.message?.replace('\n', '<br />')||'';
        this.messageColor = this.info.iconColor;
        
        switch(this.info?.iconColor) {
            case 'green':
                this.iconColor = '#b9fdaf';
                break;
            case 'orange':
                this.iconColor = '#faa62d';
                break;
            case 'red':
                this.iconColor = '#ff7171';
                break;
            default:
                this.iconColor = 'white';
        }
    }

    async openPPRQuestionnaire() {
        if (!await this.router.navigate('ppr2')) {
            console.warn(`Navigation to route "ppr2" failed!`);
        }
    }
}
